import React from "react"
import { graphql } from 'gatsby'
import Head from "../components/Head"
import '../styles/index.css'

const ProjectPage = ({ path }) => {
  return (
    <Head title="Home" />
  )
}

export const query = graphql`
query ($uid: String) {
  prismic {
    allProjects(uid: $uid) {
      edges {
        cursor,
        node {
          date,
          name,
          thumbnail,
          body {
            __typename
            ... on PRISMIC_ProjectBodyText {
              type,
              label,
              primary {
                text
              }
            }
            ... on PRISMIC_ProjectBodyImage {
              type,
              label,
              primary {
                image
              }
            }
            ... on PRISMIC_ProjectBodySlider {
              type,
              label,
              fields {
                image
              }
            }
          }
        }
      }
    }
    allFooters {
      edges {
        node {
          instagram
          linkedin
          newsletter
          copyright
        }
      }
    }
  }
}
`

export default ProjectPage
